import { buildVersion, buildTimestamp } from './build';

export const environment = {
    // Environment
    production: false,
    env: 'Development',
    // Build
    version: buildVersion,
    buildTimestamp,
    // API
    apiUrl: '/api/v1',
    apiBaseUrl: '/api',
    gtmId: 'GTM-5B6VXPFJ',
    staticResourcePath: 'https://stage-static.crsrescore.org'
};
